<template>
  <div class="lottie-component" ref="lavContainer"></div>
</template>

<script>
import lottie from "lottie-web";
import animationData from "@/assets/lotties/79952-successful.json";
export default {
  name: "lottie",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      anim: null,
      defaultOptions: {
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
      },
    };
  },
  mounted() {
    let params = { ...this.defaultOptions, ...this.options };
    this.anim = lottie.loadAnimation({
      container: this.$refs["lavContainer"],
      renderer: "svg",
      ...params,
    });
    this.$emit("animCreated", this.anim);
  },
  unmounted() {
    this.anim && this.anim.destroy();
  },
};
</script>

<style lang="scss" scoped>
.lottie-component {
  min-width: 50px;
  min-height: 50px;
  width: 100%;
  height: 100%;
}
</style>