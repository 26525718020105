export default {
  methods: {
    formatStatus(val) {
      let status = {
        LOGISTICS_EXCEPTION: {
          icon: "el-icon-warning",
          color: "#f3cd7a",
          deepColor: "#fc892b",
          type: "warning",
        },
        ARRIVED: {
          icon: "el-icon-success",
          color: "#b3fc8e",
          deepColor: "#67C23A",
          type: "success",
        },
        SIGN_AND_RECEIVE:{
          icon: "el-icon-success",
          color: "#b3fc8e",
          deepColor: "#67C23A",
          type: "success",
        },
        CANCELED: {
          icon: "el-icon-error",
          color: "#F56C6C",
          deepColor: "#F56C6C",
          type: "error",
        }
      };
      let defaultStatus = {
        icon: "el-icon-s-help",
        color: "#989898",
        deepColor: "#989898",
        type: "primary",
      };
      if (status[val]) {
        return status[val];
      } else {
        return defaultStatus;
      }
    },
  }
}