<template>
  <div class="info-wrapper">
    <div class="info-container" @click="navToOrderDetail">
      <h5 class="order-salesNo">
        {{ orderDetail.salesNo ? orderDetail.salesNo : "未知" }}
      </h5>
      <p class="info-row">
        {{ orderDetail.clientName ? orderDetail.clientName : "" }}
      </p>
      <p class="info-row">{{ orderDetail.createdAt | date }}</p>
      <p class="info-row">{{ orderDetail.orderType }}</p>
    </div>
  </div>
</template>

<script>
import { GetSaleOrderPubDetail } from "@/views/orders/api";
export default {
  name: "OrderDesc",
  props: {
    orderId: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      currentId: null,
      orderDetail: {},
    };
  },
  watch: {
    orderId: {
      handler(val) {
        this.currentId = val;
        this.initDetail();
      },
      immediate: true,
    },
  },
  methods: {
    initDetail() {
      if (this.currentId == null) {
        return;
      }
      GetSaleOrderPubDetail(this.currentId).then((res) => {
        this.orderDetail = res.data;
        // console.log("物流中的定单详情", this.orderDetail);
      });
    },
    navToOrderDetail() {
      this.$router.push({
        name: "orderDetail",
        params: {
          id: this.currentId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  width: 100%;
  text-align: left;
  .info-container {
    margin: 0 20px;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    border-radius: 10px;
    .order-salesNo {
      font-size: 15px;
      color: #14b5f5;
      margin: 0;
      margin-bottom: 10px;
    }
    .info-row {
      font-size: 14px;
      color: #6d6c6c;
      margin: 0;
      margin-bottom: 5px;
    }
  }
}
</style>