<template>
  <div class="signature-wrapper" id="wrapper">
    <div
      class="signature-container"
      :class="{ 'is-horizontal': isHorizontal }"
      id="container"
    >
      <div class="canvas-container" id="canvasContainer">
        <canvas id="signature" />
      </div>
      <div class="signature-control">
        <!-- <div class="btn normal" @click="onRotate">
          {{ isHorizontal ? "竖屏" : "横屏" }}
        </div> -->
        <div class="btn cancel" @click="onClear">清除</div>
        <div class="btn confirm" @click="onConfrim">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import SmoothSignature from "smooth-signature";
export default {
  props: {
    resultType: {
      type: String,
      default: "base64",
    },
  },
  data() {
    return {
      signature: null,
      wrapper: null,
      container: null,
      canvasContainer: null,
      isHorizontal: false,
    };
  },
  mounted() {
    this.wrapper = document.querySelector("#wrapper");
    this.container = document.querySelector("#container");
    this.canvasContainer = document.querySelector("#canvasContainer");
    this.initCanvas(
      this.canvasContainer.clientWidth,
      this.canvasContainer.clientHeight
    );
  },
  methods: {
    initCanvas(width = 1000, height = 200) {
      const canvas = document.querySelector("#signature");
      this.signature = new SmoothSignature(canvas, {
        width: width,
        height: height,
      });
      // console.log("signature", this.signature);
    },
    onClear() {
      this.signature.clear();
    },
    onConfrim() {
      let result = "";
      if (this.resultType == "base64") {
        result = this.signature.toDataURL();
      }
      if (this.resultType == "png") {
        result = this.signature.getPNG();
      }
      if (this.resultType == "jpg") {
        result = this.signature.getJPG();
      }
      this.$emit("confirm", result);
    },
    onRotate() {
      this.isHorizontal = !this.isHorizontal;
      this.signature = null;
      this.initCanvas(
        this.canvasContainer.clientHeight,
        this.canvasContainer.clientWidth
      );
      let wrapper_width = this.wrapper.clientWidth;
      let wrapper_height = this.wrapper.clientHeight;

      if (this.isHorizontal) {
        this.container.style.width = wrapper_height + "px";
        this.container.style.height = wrapper_width + "px";
      } else {
        this.container.style.width = wrapper_width + "px";
        this.container.style.height = wrapper_height + "px";
      }
      this.canvasContainer.style.width = wrapper_height - 40 + "px";
      this.canvasContainer.style.height = wrapper_width - 100 + "px";

      console.log(
        this.canvasContainer.style.width,
        this.canvasContainer.style.height
      );
      // this.initCanvas(wrapper_height - 40, wrapper_width - 100);
      this.signature.getRotateCanvas(90);
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  touch-action: none;
  overflow: hidden;
  .signature-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // border-top: 4px solid #f77272;
    // border-right: 4px solid #48ca14;
    transition: all 5s linear;
    .canvas-container {
      box-sizing: border-box;
      width: 100%;
      // border-top: 4px solid #f77272;
      // border-right: 4px solid #48ca14;
      margin: 0 auto;
      height: 250px;
      border: 1px solid #f1f1f1;
      background-color: #fff;
    }
    .signature-control {
      flex: 60px 0 0;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }

    &.is-horizontal {
      transform: rotate(90deg);
    }
  }
  .is-horizontal {
    transform: rotate(90deg);
  }

  .btn {
    box-sizing: border-box;
    padding: 8px 20px;
    font-size: 14px;
    color: #333;
    border-radius: 4px;
    & + .btn {
      margin-left: 20px;
    }
    &.confirm {
      background-color: #48ca14;
      color: #fff;
      padding: 8px 35px;
    }
    &.cancel {
      background: #f77272;
      color: #fff;
    }
    &.normal {
      background: #fff;
      color: #333;
    }
  }
}
</style>