<template>
  <div class="page-wrapper">
    <transition name="fade">
      <div class="page-loading" v-if="pageLoading">
        <Loading></Loading>
        <span class="loading-text">加载中...</span>
      </div>
    </transition>
    <div
      class="page-container"
      :class="[showSignaturePanel ? 'hidden' : '']"
      id="scroller"
      ref="scrollerRef"
    >
      <div class="page-content">
        <div class="main-info-wrapper">
          <div
            class="main-info-bg-mask"
            :style="{
              'background-color':orderDetail.statusId !== 'CANCELED' ? formatStatus(currentStatus.eventId).deepColor : '#F56C6C',
            }"
          ></div>
          <div class="main-info-content">
            <h4 class="order-status">
              {{
                orderDetail.statusName ? orderDetail.statusName : "暂无物流状态"
              }}
            </h4>
            <p class="order-status-content">
              {{
                currentStatus.content ? currentStatus.content : "暂无物流状态"
              }}
            </p>
          </div>

          <span v-if="$route.query.back" class="back" @click="$router.go(-1)"
            ><svgIcon iconClass="Cancel"
          /></span>
          <div class="main-info-card-wrapper">
            <div class="main-info-card">
              <h4 class="info-row order-name">
                {{ orderDetail.no ? orderDetail.no : "未知" }}
              </h4>
              <p class="info-row">
                司机：{{
                  orderDetail.driverName ? orderDetail.driverName : "未知"
                }}
              </p>
              <p class="info-row">
                电话：{{
                  orderDetail.driverPhoneNo ? orderDetail.driverPhoneNo : "未知"
                }}
              </p>
              <p class="info-row">
                发货日期：{{ orderDetail.deliveryDate | date }}
              </p>

              <p class="info-row" v-if="orderDetail.deliveryAddress">
                发货地址：
                <span v-if="orderDetail.deliveryAddress != null">
                  {{ orderDetail.deliveryAddress.country }}
                  {{ orderDetail.deliveryAddress.province }}
                  {{ orderDetail.deliveryAddress.city }}
                  {{ orderDetail.deliveryAddress.region }}
                  {{ orderDetail.deliveryAddress.street }}
                </span>
              </p>
            </div>
          </div>
          <!-- 已经取消的定单不显示签收按钮 -->
          <div class="sign-container" v-if="orderDetail.statusId !== 'CANCELED'">
            <div
              class="sing-btn"
              v-if="orderDetail.receiverSignature == null"
              @click="showSignaturePanel = true"
            >
              签收
            </div>
            <div
              class="sing-btn is-sign"
              v-if="orderDetail.receiverSignature !== null"
              @click="showSignatureResult = true"
            >
              已签收，点击查看签名
            </div>
          </div>
        </div>
        <div class="harvest-card-wrapper">
          <div class="harvest-card">
            <header>收货信息</header>
            <div class="content">
              <p>
                收货人：{{
                  orderDetail.receiverName ? orderDetail.receiverName : "暂无"
                }}
              </p>
              <p>
                联系方式：{{
                  orderDetail.receiverPhoneNo
                    ? orderDetail.receiverPhoneNo
                    : "暂无"
                }}
              </p>
              <p>收货日期：{{ orderDetail.receiveDate | date }}</p>
              <p class="info-row" v-if="orderDetail.receiveAddress">
                收货地址：
                <span v-if="orderDetail.receiveAddress != null">
                  {{ orderDetail.receiveAddress.country }}
                  {{ orderDetail.receiveAddress.province }}
                  {{ orderDetail.receiveAddress.city }}
                  {{ orderDetail.receiveAddress.region }}
                  {{ orderDetail.receiveAddress.street }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="product-list-wrapper">
          <h5 class="product-title">产品</h5>
          <div class="product-conut-wrapper">
            <div class="count-item box-shadow">
              <span class="item-title">金额合计</span>
              <span class="item-value">{{
                summary.amountWithTax | currency
              }}</span>
            </div>
            <div class="count-item box-shadow">
              <span class="item-title">片数合计</span>
              <span class="item-value"
                >{{ summary.tiles ? summary.tiles : 0 }} 片</span
              >
            </div>
            <div class="count-item box-shadow">
              <span class="item-title">平方数合计</span>
              <span class="item-value">
                {{ summary.squareMeters ? summary.squareMeters : 0 }} m²</span
              >
            </div>
          </div>

          <div class="product-list-container" id="productListContainer">
            <div class="scroller-list">
              <div
                class="product-item box-shadow"
                v-for="item in products"
                :key="item.id"
              >
                <header class="code">
                  <span>{{ item.sapProductName }}</span>
                </header>
                <div class="item-content">
                  <div class="item-row">SAP产品ID：{{ item.sapProductId }}</div>
                  <div class="item-row">旧产品ID：{{ item.oldProductId }}</div>
                  <div class="item-row">规格：{{ item.specification }}</div>
                  <div class="item-row">包装方式：{{ item.packingMethod }}</div>
                  <div class="item-row">色号：{{ item.batchNo }}</div>
                  <div class="item-row">
                    加工类型：{{ item.processingTypeName }}
                  </div>
                  <div class="item-row">片/箱：{{ item.bricksPerCarton }}</div>
                  <div class="item-row">片数：{{ item.bricks }} 片</div>
                  <div class="item-row">箱数：{{ item.cartons }}箱</div>
                  <div class="item-row">平方数：{{ item.squareMeters }} m²</div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-tips" v-if="products.length > 1">
            &lt; &nbsp;滑动查看 &nbsp;&gt;
          </div>
        </div>
        <OrderDesc :orderId="currentOrderId"></OrderDesc>

        <div class="order-message-wrapper" v-if="logContent.length > 0">
          <h5 class="title">物流动态</h5>
          <div class="order-message-content">
            <template v-for="log in logContent">
              <div class="order-message-item" :key="log.id">
                <h6
                  class="message-content"
                  :style="{ color: formatStatus(log.eventId).deepColor }"
                >
                  {{ log.content }}
                </h6>
                <p class="message-desc">
                  {{ log.createdAt | date_time }}
                </p>
              </div>
            </template>
          </div>
          <transition name="fade">
            <div class="message-loading" v-show="logLoading">正在获取...</div>
          </transition>
        </div>
      </div>
      <transition name="fade">
        <div class="refresh-loading" v-if="pageLoading">正在刷新...</div>
      </transition>
    </div>
    <div class="signature-panel" :class="[showSignaturePanel ? 'active' : '']">
      <div class="signature-container">
        <div class="header">
          <span class="back-btn" @click="showSignaturePanel = false">返回</span>
          <span class="signature-tips"
            >请在下方白色区域手写签名，然后点击确定</span
          >
        </div>
        <div class="signature-content">
          <Signature @confirm="handleSign" />
        </div>
      </div>
    </div>
    <div class="signature-success" v-if="isSignSuccess">
      <div class="container">
        <Lottie />
        <span class="text">签收成功</span>
        <span class="close-success" @click="handleCloseSuccess">点我返回</span>
      </div>
    </div>
    <div
      class="sign-result-wrapper"
      :class="[showSignatureResult ? 'active' : '']"
    >
      <div class="mask" @click="showSignatureResult = false"></div>
      <!-- :class="[showSignatureResult ? 'active' : '']" -->
      <div
        class="sign-result-container"
        :class="[showSignatureResult ? 'active' : '']"
      >
        <img :src="orderDetail.receiverSignature" alt="签名图片" />
        <!-- <div class="re-sign-btn" @click="handleReSign">重新签名</div> -->
        <div class="close-btn" @click="showSignatureResult = false">收起</div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Signature from "@/components/Signature.vue";
import Lottie from "@/components/Lottie.vue";
import BScroll from "better-scroll";
import {
  PutLogisticsSign,
  GetLogisticsDetail,
  GetLogisticsLogsList,
  GetLogisticsProductPubSummary,
  GetLogisticsProductList,
} from "./api";
import OrderDesc from "./components/OrderDesc.vue";
import status from "./mixins/status";
export default {
  components: { Loading, OrderDesc, Lottie, Signature },
  mixins: [status],
  data() {
    return {
      showSignatureResult: false,
      showSignaturePanel: false,
      isSignSuccess: false,
      pageLoading: true,
      pageScroller: null,
      productScroller: null,
      currentId: null,
      currentOrderId: null,
      orderDetail: {},
      currentStatus: {},
      summary: {},
      products: [],
      logContent: [],
      logLoading: false,
      logPage: 0,
      logSize: 99,
      logTotal: 0,
      isLogLast: false,
      tempSign: "",
    };
  },
  created() {
    if (this.$route.params.id) {
      this.currentId = this.$route.params.id;
    }
    if (this.$route.params.orderId) {
      this.currentOrderId = this.$route.params.orderId;
    }
    this.pageLoading = true;
    this.initDetail();
  },

  methods: {
    initScrollBar() {
      this.$nextTick(() => {
        this.pageScroller = new BScroll("#scroller", {
          // ...
          pullDownRefresh: true,
          wheel: false,
          scrollbar: true,
          click: true,
          bounce: {
            bottom: false,
          },
          // and so on
        });
        this.pageScroller.on("scrollEnd", () => {
          this.loadMessage();
        });
        this.pageScroller.on("pullingDown", () => {
          // console.log("下拉刷新");
          this.pageLoading = true;
          this.initDetail();
        });
        this.productScroller = new BScroll("#productListContainer", {
          scrollX: true,
          // and so on
        });
      });
    },
    initDetail() {
      GetLogisticsDetail(this.currentId)
        .then((res) => {
          this.orderDetail = res.data;
          // console.log("请求的数据结果", this.orderDetail);

          return GetLogisticsProductPubSummary(this.currentId);
        })

        .then((summaryResult) => {
          this.summary = summaryResult.data;
          // console.log("定单总数", summaryResult);
          return GetLogisticsProductList(this.currentId, {
            page: 0,
            size: 999,
            sapSalesOrderId: this.currentOrderId,
          });
        })
        .then((productResult) => {
          this.products = productResult.data.content;
          // console.log("定单产品", this.products);
          return GetLogisticsLogsList(this.currentId, {
            page: 0,
            size: 999,
          });
        })
        .then((statusResult) => {
          let { content } = statusResult.data;
          if (content.length > 0) {
            this.currentStatus = content[0];
          } else {
            this.currentStatus = {};
          }
          this.logPage = 0;
          this.logContent = content;
          // console.log("物流状态", statusResult);
        })
        .finally(() => {
          this.initScrollBar();
          if (this.pageScroller !== null) {
            this.pageScroller.finishPullDown();
          }
          this.pageLoading = false;
        });
    },

    loadMessage() {
      if (!this.isLogLast) {
        this.logLoading = true;
        this.pageScroller.refresh();
        this.logPage++;
        this.getLogMessage();
      }
    },

    getLogMessage() {
      GetLogisticsLogsList(this.currentId, {
        page: this.logPage,
        size: this.logSize,
      })
        .then((logResult) => {
          let { content, last, totalElements } = logResult.data;
          if (this.logPage == 0) {
            this.logContent = content;
          } else {
            this.logContent = [...this.logContent, ...content];
          }
          this.isLogLast = last;
          this.logTotal = totalElements;
        })
        .catch((err) => {
          console.log("数据请求异常", err);
        })
        .finally(() => {
          this.logLoading = false;
          this.pageScroller.refresh();
        });
    },
    handleSign(data) {
      // console.log("签名结果", data);
      // this.tempSign = data;

      PutLogisticsSign(this.currentId, { signature: data })
        .then((res) => {
          console.log("签名结果", res);
          this.isSignSuccess = true;
          this.showSignaturePanel = false;
        })
        .catch((err) => {
          console.log("签名失败", err);
        });
    },
    handleReSign() {
      this.showSignaturePanel = true;
      this.showSignatureResult = false;
    },
    handleCloseSuccess() {
      this.isSignSuccess = false;
      this.pageLoading = true;
      this.initDetail();
    },
  },
  // beforeDestroy() {
  //   if (this.scroller) {
  //     this.scroller.destroy();
  //   }
  //   if (this.productScroller) {
  //     this.productScroller.destroy();
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  font-size: 16px;
  color: #333;
  height: 100%;
  position: relative;
  overflow: hidden;
  .signature-panel {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 66;
    touch-action: none;
    transition: all 0.5s ease-in-out;
    transform: scale(0.8) translateX(200%);
    overflow: hidden;
    .signature-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #ececec;
      .header {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        .back-btn {
          padding: 6px 8px;
          font-size: 14px;
          color: #000;
          border-radius: 4px;
          border: 1px solid #ddd;
          background-color: #fff;
        }
        .signature-tips {
          font-size: 12px;
          color: #333;
        }
      }
      .signature-content {
        width: 100%;
      }
    }
    &.active {
      transform: scale(1) translateX(0%);
    }
  }
  .signature-success {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 67;
    background-color: #fff;
    overflow: hidden;
    .container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-items: center;
      flex-direction: column;
      box-sizing: border-box;
      padding: 20px 0;
      .text {
        padding-top: 10px;
        font-size: 20px;
        font-weight: 600;
      }
      .close-success {
        font-size: 14px;
        margin-top: 30px;
        color: #989898;
      }
    }
  }
  .sign-result-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 60;
    transition: all 0.2s linear;
    transform: translateY(150%);
    overflow: hidden;
    &.active {
      transform: translateY(0);
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 61;
      background-color: rgba($color: #333333, $alpha: 0.9);
    }
    .sign-result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 30%;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #fff;
      box-shadow: -2px 0 2px #ddd;
      z-index: 62;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-sizing: border-box;
      padding-top: 40px;
      transition: all 0.5s ease-in-out;
      transform: translateY(150%);
      overflow: hidden;
      &.active {
        transition-delay: 0.2s;
        transform: translateY(0);
      }
      & img {
        display: block;
        max-width: 100%;
      }
      .close-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #c0bebe;
        border-bottom: 1px solid #f1f1f1;
      }
      .re-sign-btn {
        margin-top: 40px;
        padding: 6px 30px;
        text-align: center;
        font-size: 14px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
    }
  }
  .page-container {
    height: 100%;
    overflow: hidden;
    position: relative;
    transform: scale(1) translateX(0);
    transition: all 0.5s ease-in-out;
    .sign-container {
      box-sizing: border-box;
      box-sizing: border-box;
      background-color: #fff;
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // right: 0;
      z-index: 20;
      // padding-bottom: constant(safe-area-inset-bottom); //兼容 IOS<11.2
      // padding-bottom: env(safe-area-inset-bottom);
      overflow: hidden;
      .sing-btn {
        box-sizing: border-box;
        margin: 0 20px 0 20px;
        padding: 8px 10px;
        background-color: #f39881;
        color: #fff;
        border-radius: 20px;
        &.is-sign {
          background-color: #67c23a;
        }
      }
      .sign-img {
        box-sizing: border-box;
        margin: 0 10px;
        & img {
          display: block;
          max-width: 100%;
        }
      }
    }

    .refresh-loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #000;
    }
    .page-content {
      box-sizing: border-box;
      padding-bottom: constant(safe-area-inset-bottom); //兼容 IOS<11.2
      padding-bottom: env(safe-area-inset-bottom);
    }
    &.hidden {
      transform: scale(0.8) translateX(-200%);
    }
  }
  .box-shadow {
    box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
  }
  .page-loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 66;
    .loading-text {
      margin-top: 20px;
      font-size: 14px;
      color: #989898;
    }
  }
}
.main-info-wrapper {
  box-sizing: border-box;
  width: 100%;
  color: #fff;

  position: relative;
  overflow: hidden;
  h3,
  h4,
  h5,
  p {
    margin: 0;
  }
  .back {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #fff;
    font-size: 20px;
  }
  & .main-info-bg-mask {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40%;
    background-color: #14b5f5;
    z-index: 1;
  }
  .main-info-content {
    position: relative;
    color: #fff;
    z-index: 3;
    overflow: hidden;
    .order-status {
      margin: 40px 10% 20px 10%;
      font-size: 20px;
    }
    .order-status-content {
      margin: 0 10%;
      font-size: 15px;
    }
  }

  .order-price {
    font-size: 18px;
    margin: 20px auto;
    color: #000;
  }
  .main-info-card-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    position: relative;
    .main-info-card {
      box-sizing: border-box;
      margin: 0 10px;
      padding: 15px;
      border-radius: 10px;
      color: #000;
      background-color: #fff;
      box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
      position: relative;
      z-index: 1;
      text-align: left;
      font-size: 13px;
      .info-row {
        color: #6d6c6c;
        margin-bottom: 6px;
      }
      .order-name {
        color: #000;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .order-type {
        position: absolute;
        bottom: 10px;
        right: 20px;
        color: #f39881;
        font-size: 12px;
      }
    }
    &::after {
      content: "";
      width: 100%;
      top: 20%;
      left: 0;
      bottom: -2px;
      right: 0;
      background-color: #fff;
      position: absolute;
    }
  }

  // &::after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 40%;
  //   background-color: #14b5f5;
  // }
}
.product-list-wrapper {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
  .product-title {
    margin: 10px auto;
    font-size: 17px;
  }
  .product-conut-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 0 5px;
    display: flex;
    margin-bottom: 10px;
    .count-item {
      flex: 1;
      margin: 0 5px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 4px;
      .item-title {
        font-size: 13px;
        padding-bottom: 5px;
      }
      .item-value {
        font-size: 13px;
      }
    }
  }
  .product-list-container {
    box-sizing: border-box;
    width: 100%;
    .scroller-list {
      padding: 0 0 0 10px;
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      .product-item {
        flex: 300px 0 0;
        width: 300px;
        margin-right: 10px;
        text-align: left;
        padding: 10px;
        font-size: 14px;
      }
      .code {
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 550;
        display: flex;
        justify-content: space-between;
      }
      .item-row {
        margin-bottom: 4px;
      }
    }
  }
  .scroll-tips {
    font-size: 13px;
    color: #989898;
    text-align: center;
    margin-top: 10px;
  }
}
.contact-card {
  box-sizing: border-box;
  margin: 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
  background-color: #fff;
  text-align: left;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .card-content {
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
    font-size: 14px;
    .contact-name {
      margin: 0;
      font-size: 16px;
      color: #000;
      font-weight: 800;
      margin-bottom: 15px;
    }
    .contact-info {
      margin: 6px auto;
    }
  }
  .card-footer {
    box-sizing: border-box;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    background-color: #f0f0f0;
  }
  .bage {
    box-sizing: border-box;
    padding: 4px 10px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    background-color: #f39881;
    border-bottom-left-radius: 10px;
    color: #fff;
  }
}
.harvest-card-wrapper {
  box-sizing: border-box;
  padding: 0 0;
  background-color: #fff;
  overflow: hidden;
  .harvest-card {
    box-sizing: border-box;
    margin: 20px 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
    background-color: #fff;
    text-align: left;
    display: flex;
    font-size: 13px;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    header {
      font-size: 15px;
      color: #000;
      font-weight: 600;
      margin-bottom: 10px;
      padding: 0 4px;
    }
    .content {
      width: 100%;
      p {
        padding: 0 5px;
        margin: 0;
        margin-bottom: 6px;
        box-sizing: border-box;
        color: #6d6c6c;
      }
    }
  }
}

.order-message-wrapper {
  box-sizing: border-box;
  border-bottom: 1px dashed #f2f2f2;
  padding-bottom: 30px;
  & .title {
    font-size: 17px;
    color: #000;
    margin: 15px auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
  }
  .order-message-content {
    margin: 5px 20px;
    // box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
    // border-radius: 10px;
    position: relative;
    .order-message-item {
      box-sizing: border-box;
      margin-bottom: 10px;
      padding: 10px 0;
      text-align: left;
      margin-bottom: 10px;
      border-bottom: 1px dashed #dddcdc;
      &:last-child {
        border-bottom: none;
      }
      .message-content {
        margin: 0 0 5px 0;
        font-size: 14px;
        font-weight: 400;
        color: #b1afaf;
      }
      .message-desc {
        margin: 0;
        font-size: 12px;
        color: #b1afaf;
      }
    }
  }
  .message-loading {
    width: 100%;
    font-size: 13px;
    color: #989898;
    text-align: center;
    padding-bottom: 15px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>