import { api } from "@/request/api";
import { apiSap } from "@/request/api_sap"
import qs from "qs";

/**
 * @param {Object} params 获取SAP公开定单产品
 */
export function GetSaleOrdersPubProductList(orderId = null, params = {}) {
    return new Promise((resolve, reject) => {
        apiSap.get(`/sales/orders/${orderId}/products/pub?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`).then(res => {
            if (res.status === 200 || res.status === 206) {
                resolve(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 获取公开定单详情
 * @param {Number} id 
 */
export function GetSaleOrderPubDetail(id) {
    return new Promise((resolve, reject) => {
        apiSap.get(`/sales/orders/${id}/pub`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取公开定单状态
 * @param {Number} id 
 */
export function GetSaleOrderPubCurrentStatus(id) {
    return new Promise((resolve, reject) => {
        apiSap.get(`/sales/orders/${id}/statuses/current/pub`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取公开定单汇总数
 * @param {Number} id 
 */
export function GetSaleOrderPubSummary(id) {
    return new Promise((resolve, reject) => {
        apiSap.get(`/sales/orders/${id}/products/summary/pub`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


/**
 * @param {Object} params 获取定单公开动态
 */
export function GetSaleOrdersPubMessage(params = {}) {
    return new Promise((resolve, reject) => {
        api.get(`/messages/pub?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`).then(res => {
            if (res.status === 200 || res.status === 206) {
                resolve(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}
